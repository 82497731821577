import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';

export default {
    list: OrderList,
    edit: OrderEdit,
    icon: ShoppingCartIcon,
    recordRepresentation: 'reference',
};
