import { LoadingIndicator, LocalesMenuButton } from 'react-admin';

import { ThemeSwapper } from '../themes/ThemeSwapper';
import { Grid } from '@mui/material';

export const AppBarToolbar = () => (
    <>
        <Grid sx={{ display: "flex", alignItems: "center"}}>
            <LocalesMenuButton />
            <ThemeSwapper />
            <LoadingIndicator />
        </Grid>
    </>
);
